import { lazy, Suspense, useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Loader from "./Components/Loader/Loader";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { VerifyUserFunction } from "./helper/Node/helper";
import AuthContext from "./Context/AuthContext";
import ChatHistoryContext from "./Context/ChatHistoryContext";
import ResetPassword from "./Pages/ResetPassword/ResetPassword";

const Login = lazy(() => import("./Pages/Login/Login"));
const Sandbox = lazy(() => import("./Sandbox"));
const Error404 = lazy(() => import("./Pages/Error404/Error404"));
const ForgetPassword = lazy(() => import("./Pages/ForgetPassword/ForgetPassword"));

function App() {
  const [user, setUser] = useState(false);
  const [loadUser, setLoadUser] = useState(false);
  const [allLinks, setAllLinks] = useState([]);
  const [currentCapacity, setCurrentCapacity] = useState(0);

  const check_user = async () => {
    setLoadUser(true);
    try {
      const response = await VerifyUserFunction();
      setCurrentCapacity(response.data.currentCapacity);
      setUser(response?.data.user);

      // Formatting the links
      setAllLinks(
        response.data.data.map((item) => ({
          _id: item._id,
          title: item.title,
          updatedAt: item.updatedAt,
        }))
      );
    } catch (err) {
      // console.error("Error:", err);
    }
    setLoadUser(false);
  };

  useEffect(() => {
    check_user();
  }, []);

  return (
    <div className="App">
      {!loadUser ? (
        <>
          <ToastContainer />
          <Suspense fallback={<Loader />}>
            <AuthContext.Provider value={{ user, setUser, currentCapacity, setCurrentCapacity }}>
              <ChatHistoryContext.Provider value={{ allLinks, setAllLinks }}>
                <Router>
                  <Routes>
                    {user ? (
                      <Route path="/*" element={<Sandbox />} />
                    ) : (
                      <>
                        <Route path="/" element={<Login />} />
                        <Route path="*" element={<Error404 />} />
                        <Route path="/forget-password" element={<ForgetPassword />} />
                        <Route
                          path="/reset-password/auth/:token"
                          element={<ResetPassword/>}
                        />
                      </>
                    )}
                  </Routes>
                </Router>
              </ChatHistoryContext.Provider>
            </AuthContext.Provider>
          </Suspense>
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
}

export default App;
