import React from "react";
import "./LoadingButton.css";
import { CircularProgress } from "@mui/material";

const LoadingButton = ({ message, bgColor, color, borderRadius, padding, fontWeight }) => {
  return (
    <div
      className="loading-button"
      style={{
        borderRadius: borderRadius,
        padding: padding,
        backgroundColor: bgColor, // corrected from bgColor to backgroundColor
      }}
    >
      <CircularProgress
        className="loading-button-progress"
        style={{
          color: color,
          backgroundColor: bgColor, // corrected from bgColor to backgroundColor
          width: "20px",
          height: "20px",
        }}
      />
      <span className="loading-button-message" style={{ fontWeight: fontWeight }}>
        {message}
      </span>
    </div>
  );
};

export default LoadingButton;
