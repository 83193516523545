import React, { useState, useCallback } from "react";
import logo from "../../Assets/truxt_logo_login.png";
import { useNavigate, useParams } from "react-router-dom";
import "./ResetPassword.css";
import { Button } from "react-bootstrap";
import LoadingButton from "../../Components/Buttons/LoadingButton";
import GIF from "../../Assets/reset-password/GIF_SUCCESS.svg";
import { ForgetPasswordAPI } from "../../helper/Node/helper";

function ResetPassword() {
  const [credentials, setCredentials] = useState({ password: "", confirmPassword: "" });
  const [disableInput, setDisableInput] = useState(false);
  const [errors, setErrors] = useState({});
  const [updated, setUpdated] = useState(false);

  const { token } = useParams();
  const navigate = useNavigate();

  const inputFields = [
    {
      label: "Password",
      type: "password",
      name: "password",
      id: "password",
      placeholder: "Enter Password",
      error: errors.password,
    },
    {
      label: "Confirm Password",
      type: "password",
      name: "confirmPassword",
      id: "confirm-password",
      placeholder: "Enter Confirm Password",
      error: errors.confirmPassword,
    },
  ];

  const handleChangeCredentials = useCallback((event) => {
    setErrors({});
    const { name, value } = event.target;
    setCredentials((prevCredentials) => ({
      ...prevCredentials,
      [name]: value,
    }));
  }, []);

  const validate = () => {
    const newErrors = {};

    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

    // Password validation
    if (!credentials.password) {
      newErrors.password = "Password is required";
    }

    // Confirm password validation
    if (credentials.confirmPassword !== credentials.password) {
      newErrors.confirmPassword = "Passwords do not match";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handlePasswordReset = async (event) => {
    event.preventDefault();
    setDisableInput(true);

    // Validate inputs
    if (!validate()) {
      setDisableInput(false);
      return;
    }

    try {
      // Attempt the password reset API call
      const response = await ForgetPasswordAPI(token, credentials);
      setUpdated(true);
      setTimeout(() => {
        navigate("/");
      }, 2000);
    } catch (err) {
      console.log(err);
      const newErrors = {};
      newErrors.loginStatus = err.response.data.message;
      setErrors(newErrors);

    } finally {
      setDisableInput(false);
    }
  };

  return (
    <div className="Login">
      <div className="login_content_wrapper">
        <div className="content_container">
          <div>
            <img src={logo} alt="Error in Loading..." />
            <hr className="colored-hr" />
            <div className="content_container_second">
              <p>Empowering</p>
              <p className="bold-headline">CUSTOMER SUPPORT</p>
              <p>
                <span>With</span>
                <span className="bold-headline">Gen AI</span>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="login_wrapper">
        {updated ? (
          <div className="success-wrapper">
            <img src={GIF} alt="Error in loading" />
            <h2>Your password is successfully updated and you are redirecting to login page.</h2>
          </div>
        ) : (
          <div className="login_container">
            <h1>Reset Password?</h1>

            {inputFields.map(({ label, type, name, id, placeholder, ref, error }) => (
              <div className="input_section" key={id}>
                <label htmlFor={id}>{label}</label>
                <input
                  type={type}
                  name={name}
                  id={id}
                  placeholder={placeholder}
                  value={credentials[name]}
                  ref={ref}
                  onChange={handleChangeCredentials}
                  disabled={disableInput}
                />
                {error && <div className="error-message">{error}</div>}
              </div>
            ))}

            <Button variant="primary" onClick={handlePasswordReset} disabled={disableInput}>
              {disableInput ? (
                <div className="loading-button">
                  <LoadingButton message="Sending" />
                </div>
              ) : (
                "Update Password"
              )}
            </Button>

            {errors.loginStatus && (
              <span style={{ color: "red", textTransform: "capitalize" }}>
                {errors.loginStatus}
              </span>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default ResetPassword;
