import axios from "axios";

// Function to dynamically get headers with Authorization token
const getHeaders = () => ({
  "Content-Type": "application/json",
  Authorization: `Bearer ${localStorage.getItem("token")}`,
});

const baseURL = process.env.REACT_APP_API;

const getUsers = async () => {
  try {
    const status = await axios.get(`${baseURL}/api/management/get-users`, {
      headers: getHeaders(), // Use the getHeaders function here
    });
    return status;
  } catch (err) {
    console.log(err);
    return err;
  }
};

const AddUserFunction = async (userDetails) => {
  try {
    const status = await axios.post(`${baseURL}/api/management/add-user`, userDetails, {
      headers: getHeaders(), // Use the getHeaders function here
    });
    return status;
  } catch (error) {
    throw error;
  }
};

const DeleteUserFunction = async (UserID) => {
  try {
    const status = await axios.delete(
      `${baseURL}/api/management/delete-user/${UserID}`,
      { headers: getHeaders() } // Use the getHeaders function here
    );
    return status;
  } catch (error) {
    throw error;
  }
};

const EditUserFunction = async (UserID, updatedDetails) => {
  try {
    const status = await axios.put(
      `${baseURL}/api/management/update-user/${UserID}`,
      updatedDetails,
      { headers: getHeaders() } // Use the getHeaders function here
    );
    return status;
  } catch (err) {
    throw err;
  }
};

const LoginUserFunction = async (credentials) => {
  try {
    const status = await axios.post(`${baseURL}/api/auth/login`, credentials);
    return status;
  } catch (err) {
    throw err;
  }
};

const VerifyUserFunction = async () => {
  if (!localStorage.getItem("token")) {
    console.error("Token does not exist. Aborting HTTP request.");
    return;
  }
  try {
    const status = await axios.post(
      `${process.env.REACT_APP_API}/api/verify/user`,
      {},
      {
        headers: getHeaders(),
      }
    );
    return status;
  } catch (err) {
    throw err;
  }
};

const updateMemoryFunction = async (inputFilesArray, operation) => {
  try {
    const files = Array.from(inputFilesArray).map((file) => {
      return operation ? { file_name: file.name, file_size: file.size } : file;
    });
    if (operation) {
      const status = await axios.post(
        `${baseURL}/api/appdata/files`,
        { files },
        { headers: getHeaders() }
      );
      return status;
    } else {
      const status = await axios.post(
        `${baseURL}/api/appdata/delete`,
        { files },
        { headers: getHeaders() }
      );
      return status;
    }
  } catch (err) {
    throw err;
  }
};

const getUsersAnalytics = async () => {
  try {
    const status = await axios.get(`${baseURL}/api/analytics`, {
      headers: getHeaders(),
    });
    return status;
  } catch (err) {
    throw err;
  }
};

const handleLikesAndDislikes = async (id, value) => {
  try {
    const status = await axios.put(
      `${process.env.REACT_APP_API}/api/conversations/rate/${id}`,
      {
        likeStatus: value,
      },
      {
        headers: getHeaders(),
      }
    );
    return status;
  } catch (err) {
    throw err;
  }
};

const fetchChats = async (id) => {
  try {
    const status = await axios.get(`${baseURL}/api/conversations/${id}`, {
      headers: getHeaders(),
    });
    return status;
  } catch (err) {
    throw err;
  }
};

const AddChatAPI = async (sessionID, query, response, modeIndex) => {
  const body = {
    messageObject: {
      query: query,
      response: response,
    },
    modeIndex: modeIndex,
  };
  try {
    const status = await axios.post(`${baseURL}/api/conversations/store/${sessionID}`, body, {
      headers: getHeaders(),
    });
    return status;
  } catch (err) {
    throw err;
  }
};

const DeleteSessions = async (session) => {
  try {
    const status = await axios.post(
      `${baseURL}/api/conversations/d/${session}`,
      { flag: true },
      { headers: getHeaders() }
    );
    return status;
  } catch (err) {
    throw err;
  }
};

// const DeleteMessageHelper = async (message_id) => {
//   try {
//     const status = await axios.post(
//       `${baseURL}/api/conversations/m/${chat_id}`,
//       { messagesId: message_id },
//       {
//         headers: getHeaders(),
//       }
//     );
//     return status;
//   } catch (error) {
//     console.log(error);
//   }
// };

const SendPasswordResetLinkAPI = async (credentials) => {
  try {
    const status = await axios.post(`${baseURL}/api/auth/forgot-password`, credentials);
    return status;
  } catch (err) {
    throw err;
  }
};

const ForgetPasswordAPI = async (token, credentials) => {
  try {
    const response = await axios.post(`${baseURL}/api/auth/reset-password`, credentials, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (err) {
    console.error("Error in ForgetPasswordAPI:", err);
    throw err;
  }
};

export default getUsers;
export {
  fetchChats,
  AddChatAPI,
  AddUserFunction,
  DeleteUserFunction,
  EditUserFunction,
  LoginUserFunction,
  VerifyUserFunction,
  updateMemoryFunction,
  getUsersAnalytics,
  handleLikesAndDislikes,
  DeleteSessions,
  // DeleteMessageHelper
  SendPasswordResetLinkAPI,
  ForgetPasswordAPI,
};
